import './styles.scss';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { UserTypeEnum } from '@enums/user-type.enum';
import { ITenant } from '@interfaces/tenant';
import { IUser } from '@interfaces/user';
import {
    BaseRecord,
    useCreate,
    useGetIdentity,
    useNavigation,
    useTranslate,
} from '@refinedev/core';
import { Button, Checkbox, Col, Form, Input, Row, Select, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { TenantService } from 'services/api/tenant.service';

enum CreateNotificationFormEnum {
    Subject = 'subject',
    Content = 'content',
}

interface ICreateNotification {
    subject: string;
    message: string;
    tenantIds: string[];
    isSendAllTenant: boolean;
}

export const tenantService = new TenantService();

export const NotificationPortalCreateIndex: React.FC = () => {
    const [form] = Form.useForm<ICreateNotification>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isApplyAllTenant, setIsApplyAllTenant] = useState<boolean>(false);
    const [allTenants, setAllTenants] = useState<{ label: string; value: string }[]>([]);
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();

    const { goBack, push } = useNavigation();

    const translate = useTranslate();
    const { mutate } = useCreate<BaseRecord>();

    useEffect(() => {
        getAllTenants();
        setIsApplyAllTenant(true);
    }, []);

    const onSubmit = async () => {
        try {
            const isValid = await form.validateFields();

            if (isValid) {
                setIsLoading(true);
                const formValue = form.getFieldsValue();
                formValue.tenantIds = isApplyAllTenant ? [] : formValue.tenantIds;
                formValue.isSendAllTenant = isApplyAllTenant;

                mutate(
                    {
                        resource: 'v1/tenant/notification-portal',
                        values: formValue,
                        successNotification: { message: 'Successfully send', type: 'success' },
                    },
                    {
                        onError: (_) => {
                            setIsLoading(false);
                        },
                        onSuccess: (_) => {
                            setIsLoading(false);
                            goBack();
                        },
                    },
                );
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    const getAllTenants = async () => {
        const tenants = await tenantService.getTenants();

        if (tenants?.length) {
            setAllTenants(
                tenants.map((tenant: ITenant.ITenantInfor) => {
                    return {
                        value: tenant.id,
                        label: `${tenant.name} - ${tenant.email}` || '',
                    };
                }),
            );
        }
    };

    if (userIdentity?.userType !== UserTypeEnum.SYS_ADMIN) {
        push('/404');

        return <></>;
    }

    return (
        <section className="create-notification-container w-full h-full">
            <Spin spinning={isLoading}>
                <Row className="w-full h-full">
                    <Col xs={24} className="create-notification-col h-full">
                        <Row>
                            <Col span={24}>
                                <section className="notification-action-container w-ful">
                                    <Row className="notification-action-row w-full h-full">
                                        <Col
                                            xs={12}
                                            className="notification-action-col-flex items-center"
                                        >
                                            <span className="create-notification-title">
                                                {translate('notification_portal.send_notification')}
                                            </span>
                                            <Tooltip
                                                title={
                                                    <span>
                                                        {translate(
                                                            'notification_portal.tooltip_create',
                                                        )}
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleOutlined
                                                    style={{ color: 'grey' }}
                                                    className="ml-2"
                                                />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </section>
                            </Col>
                        </Row>
                        <Form
                            form={form}
                            id="createNotificationForm"
                            name="create-notification"
                            layout="vertical"
                            className="create-notification-form w-full"
                        >
                            <Row className="create-notification-form-row w-full h-full">
                                <Col xs={24} className="create-notification-form-col col-left">
                                    <Form.Item>
                                        <>
                                            <span className="required">*</span>{' '}
                                            <span className="mr-3 font-bold notification-label">
                                                {translate('notification_portal.to')}
                                            </span>
                                        </>
                                        <Checkbox
                                            checked={isApplyAllTenant}
                                            onChange={(e) => {
                                                setIsApplyAllTenant(e.target.checked);
                                                form.resetFields(['tenantIds']);
                                            }}
                                        >
                                            {translate('notification_portal.send_noti_all_tenant')}
                                        </Checkbox>
                                    </Form.Item>
                                    {!isApplyAllTenant && (
                                        <Form.Item
                                            name="tenantIds"
                                            initialValue={[]}
                                            className="mt-2"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: translate(
                                                        'notification_portal.tenant_required',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <Select
                                                className="custom-select"
                                                placeholder={translate(
                                                    'notification_portal.select_tenant',
                                                )}
                                                options={allTenants}
                                                mode="multiple"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    !!option &&
                                                    option.label
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                                maxTagCount={'responsive'}
                                                maxTagPlaceholder={(omittedValues) => (
                                                    <Tooltip
                                                        title={omittedValues.map(({ label }) => (
                                                            <div>{label}</div>
                                                        ))}
                                                    >
                                                        <span>+ {omittedValues.length}...</span>
                                                    </Tooltip>
                                                )}
                                            />
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        className="notification-form-item form-item-subject"
                                        label={translate('notification_portal.subject')}
                                        name={CreateNotificationFormEnum.Subject}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate(
                                                    'notification_portal.subject_required',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        className="notification-form-item form-item-message"
                                        label={translate('notification_portal.message')}
                                        name={CreateNotificationFormEnum.Content}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate(
                                                    'notification_portal.message_required',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input.TextArea />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col xs={24} className="flex justify-end">
                                <Button onClick={goBack}>
                                    {translate('notification_portal.cancel')}
                                </Button>
                                <Button
                                    className="action-button create-btn ml-4"
                                    type="primary"
                                    onClick={onSubmit}
                                    disabled={isLoading}
                                >
                                    {translate('notification_portal.send')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </section>
    );
};

import './styles.scss';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { CustomSearch } from '@components/modules/custom-search';
import { UserTabContent } from '@components/user/user-tab-content';
import { PERMISSIONS } from '@constants/permission';
import { Role } from '@interfaces/role';
import { IUser } from '@interfaces/user';
import { EditButton } from '@refinedev/antd';
import {
    useCreate,
    useDelete,
    useGetIdentity,
    useList,
    usePermissions,
    useTable,
    useTranslate,
} from '@refinedev/core';
import { Button, Col, Image, notification, Popconfirm, Row, Table, Tabs } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { CreateRoleDrawer } from 'components/setting/create-role-drawer/create-role-drawer';
import { CreateUserDrawer } from 'components/setting/invite-user-drawer/invite-user-drawer';
import { UserStatusEnum } from 'enums/user-status.enum';
import React, { useEffect, useState } from 'react';

import { Chip } from '../../../components/modules/chip';
import { PAGE_SIZE, SA_ROLE } from '../../../constants/index.constant';

interface IUsersSettingProps {
    name?: string;
}

export const TENANT_RESOURCE_API = 'v1/tenant';

enum TabsEnum {
    USERS = 'usersSetting',
    ROLES = 'rolesSetting',
}

export const UsersSetting: React.FC<IUsersSettingProps> = () => {
    /* State */
    const [tab, setTab] = useState<TabsEnum>(TabsEnum?.USERS);
    const { data: useIdentity } = useGetIdentity<IUser.IUserDto>();
    //User
    const [selectedUser, setSelectedUser] = useState<IUser.IUserDto | null>(null);
    const [openCreateUser, setOpenCreateUser] = useState<boolean>(false);
    const translate = useTranslate();
    // Role
    const [selectedRole, setSelectedRole] = useState<Role.IRole | null>(null);
    const [openRoleDrawer, setOpenRoleDrawer] = useState<boolean>(false);

    //Hooks
    const { mutate } = useCreate();
    const { mutate: deleteUser } = useDelete();
    const { mutate: deleteRole } = useDelete();

    const { setFilters, tableQueryResult, current, setCurrent, pageSize, setPageSize } =
        useTable<IUser.IUserDto>({
            resource: `${TENANT_RESOURCE_API}/staffs`,
            sorters: {
                initial: [
                    {
                        field: 'createdAt',
                        order: 'desc',
                    },
                ],
            },

            pagination: {
                mode: 'server',
                pageSize: PAGE_SIZE,
            },
        });

    const { data: roles, refetch: getRoles } = useList<Role.IRole>({
        resource: 'v1/roles',
        queryOptions: { enabled: false },
    });

    const { data: rights } = usePermissions<string[]>();

    /* Effects */

    useEffect(() => {
        if (selectedRole) {
            setOpenRoleDrawer(true);
        }
    }, [selectedRole]);

    useEffect(() => {
        getRoles();
    }, [useIdentity]);

    useEffect(() => {
        if (selectedUser) {
            setOpenCreateUser(true);
        }
    }, [selectedUser]);

    /* Methods */
    const createUser = () => {
        setOpenCreateUser(true);
        setSelectedUser(null);
    };

    const closeCreateUser = () => {
        setOpenCreateUser(false);
        setSelectedUser(null);
    };

    const onInviteUser = () => {
        setOpenCreateUser(false);
        tableQueryResult.refetch();
    };

    const onAddRole = () => {
        if (selectedRole) {
            setSelectedRole(null);
        }
        setOpenRoleDrawer(true);
    };

    const onCloseRoleDrawer = () => {
        setOpenRoleDrawer(false);
        setSelectedRole(null);
    };

    const onChangedRole = () => {
        setOpenRoleDrawer(false);
        getRoles();
    };

    const onChangeTab = (e: TabsEnum) => {
        setTab(e);
    };

    const sendEmail = (id: string) => {
        if (!id) return;
        mutate(
            {
                resource: `${TENANT_RESOURCE_API}/staff/invite/${id}`,
                values: {},
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: (data) => {
                    if (data?.data) {
                        notification.success({
                            message: translate('users.invite_user_successfully'),
                            type: 'success',
                        });
                    }
                },
            },
        );
    };

    const getFullname = (user: IUser.IUserDto): string =>
        `${user?.firstName ? user?.firstName + ' ' : ''}${user?.lastName ?? ''}`;

    const editRole = (event: any, item: Role.IRole) => {
        event?.preventDefault();
        if (!item) {
            return;
        }

        setSelectedRole({ ...item });
    };

    const editUser = (event: any, item: IUser.IUserDto) => {
        event?.preventDefault();

        setSelectedUser({ ...item });
    };

    const onDeleteUser = (id: string) => {
        if (!id) return;
        deleteUser(
            {
                resource: `${TENANT_RESOURCE_API}/staff`,
                id,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: (data) => {
                    if (data?.data) {
                        notification.success({
                            message: translate('users.delete_user_successfully'),
                            type: 'success',
                        });
                        tableQueryResult.refetch();
                    }
                },
                onError(error) {
                    notification.error({
                        message: error?.message
                            ? translate(`users.${error?.message}`)
                            : translate('users.delete_user_failed'),
                        type: 'error',
                    });
                },
            },
        );
    };

    const onDeleteRole = (id: string) => {
        if (!id) return;
        deleteRole(
            {
                resource: `v1/roles`,
                id,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: (data) => {
                    if (data?.data) {
                        notification.success({
                            message: translate('users.delete_role_successfully'),
                            type: 'success',
                        });
                        getRoles();
                    }
                },
                onError(error) {
                    notification.error({
                        message: translate('users.delete_role_failed'),
                        type: 'error',
                    });
                },
            },
        );
    };

    const userColumns: ColumnsType<IUser.IUserDto> = [
        {
            title: translate('users.fullname'),
            dataIndex: 'fullname',
            key: 'fullname',
            render: (_: string, item) => {
                return <div className="table-tbody-text">{getFullname(item)}</div>;
            },
        },
        {
            title: translate('users.email'),
            dataIndex: 'email',
            key: 'email',
            render: (_: string, item) => {
                return <div className="table-tbody-text">{item?.email ?? ''}</div>;
            },
        },
        {
            title: translate('users.status.status'),
            dataIndex: 'status',
            key: 'status',
            render: (_: string, item) => {
                return item?.status ? renderStatus(item.status) : null;
            },
        },
        {
            title: '',
            width: 100,
            dataIndex: 'action',
            render: (_: string, item) => {
                return (
                    <div
                        className="user-cell action-cell flex justify-end"
                        style={{ minWidth: '65px' }}
                    >
                        {!item.status ||
                        (item.status === UserStatusEnum.PENDING &&
                            (rights || []).includes(PERMISSIONS.USER_MANAGER_CREATE)) ? (
                            <Button
                                className="mr-5 p-0 w-4 border-0"
                                onClick={() => sendEmail(item?.id ?? '')}
                                title={translate('users.invite_tooltip')}
                            >
                                <Image
                                    preview={false}
                                    src="/images/icons/send.svg"
                                    alt="more"
                                    className="cursor-pointer"
                                />
                            </Button>
                        ) : null}

                        {(rights || []).includes(PERMISSIONS.USER_MANAGER_EDIT) &&
                        useIdentity?.id !== item.id ? (
                            <EditButton
                                icon={false}
                                recordItemId={item?.id}
                                resource="user_manager"
                                onClick={(e) => editUser(e, item)}
                                className="mr-5 p-0 border-0"
                                title={translate('users.edit_tooltip')}
                            >
                                <EditOutlined />
                            </EditButton>
                        ) : null}

                        {(rights || []).includes(PERMISSIONS.USER_MANAGER_DELETE) &&
                        useIdentity?.id !== item.id ? (
                            <Popconfirm
                                placement="left"
                                title={translate('users.delete_user_confirm')}
                                onConfirm={() => onDeleteUser(item?.id as string)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className="p-0 border-0 delete-user-button"
                                    title={translate('users.delete_user')}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        ) : null}
                    </div>
                );
            },
        },
    ];

    const roleColumns: ColumnsType<Role.IRole> = [
        {
            title: translate('users.role_name'),
            dataIndex: 'roleName',
            render: (_: string, item) => {
                return <div className="table-tbody-text">{item?.name}</div>;
            },
        },
        {
            title: '',
            dataIndex: 'action',
            render: (_: string, item) => {
                return (
                    <div className="role-cell action-cell flex justify-end cursor-pointer">
                        {(rights || []).includes(PERMISSIONS.USER_MANAGER_EDIT) ? (
                            <EditOutlined
                                onClick={(e) => editRole(e, item)}
                                className="mr-5 p-0 border-0"
                                title={translate('users.edit_role_tooltip')}
                            />
                        ) : null}

                        {(rights || []).includes(PERMISSIONS.USER_MANAGER_DELETE) ? (
                            <Popconfirm
                                placement="left"
                                title={translate('users.delete_role_confirm')}
                                onConfirm={() => onDeleteRole(item?.id as string)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className="p-0 border-0 delete-user-button"
                                    title={translate('users.delete_role_user')}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        ) : null}
                    </div>
                );
            },
        },
    ];

    const renderStatus = (code: UserStatusEnum) => {
        const label = translate(`users.status.${code}`);
        let type = '';
        switch (code) {
            case UserStatusEnum.PENDING:
                type = 'warning';
                break;
            case UserStatusEnum.ACTIVE:
                type = 'success';
                break;
            case UserStatusEnum.DEACTIVATED:
                type = 'disable';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    const RoleTabContent = () => (
        <Row className="roles-table-container">
            <Col span={24} className="flex flex-col">
                <div className="roles-total-container flex justify-between">
                    <div className="total-roles">
                        <span className="all-roles">{translate('users.all_roles')}</span>
                        <span className="total-number-roles ml-2">
                            <span>{roles?.data?.length ?? 0}</span>
                            <span className="ml-1">
                                {(roles?.data?.length ?? 0) <= 1
                                    ? translate('users.role').toLowerCase()
                                    : translate('users.roles').toLowerCase()}
                            </span>
                        </span>
                    </div>
                </div>
                <Table
                    className="roles-table"
                    columns={roleColumns}
                    dataSource={roles?.data}
                    scroll={{ y: '100%', x: '800px' }}
                    pagination={false}
                    rowKey="id"
                />
            </Col>
        </Row>
    );

    const onSearchUser = (event: any) => {
        setCurrent(1);
        setFilters([
            {
                field: 'filter',
                operator: 'eq',
                value: event?.target?.value,
            },
        ]);
    };

    return (
        <section className="tenant-users-setting-container">
            {(rights || []).includes(PERMISSIONS.USER_MANAGER_CREATE) ? (
                <Row className="w-full h-10 header-action-row my-5">
                    <Col span={12} className="header-action-col justify-start">
                        <CustomSearch
                            placeholder="Search Users"
                            className={'search-item'}
                            onChange={(event: any) => onSearchUser(event)}
                            allowClear={true}
                        />
                    </Col>
                    <Col span={12} className="header-action-col justify-end">
                        <Button
                            icon={false}
                            className="create-user-button"
                            type="primary"
                            onClick={(e) => (tab === TabsEnum.USERS ? createUser() : onAddRole())}
                        >
                            <Image preview={false} src={'/images/icons/plus.svg'} />
                            <span className="ml-2">
                                {tab === TabsEnum.USERS
                                    ? translate('users.create_user')
                                    : translate('users.add_role')}
                            </span>
                        </Button>
                    </Col>
                </Row>
            ) : null}

            <Tabs onChange={(e) => onChangeTab(e as TabsEnum)} defaultActiveKey={TabsEnum.USERS}>
                <Tabs.TabPane tab={translate('users.user')} key={TabsEnum.USERS}>
                    <UserTabContent
                        cols={userColumns}
                        current={current}
                        pageSize={pageSize}
                        setCurrent={setCurrent}
                        setPageSize={setPageSize}
                        tableResult={tableQueryResult}
                        total={tableQueryResult?.data?.total as number}
                    />
                    {openCreateUser ? (
                        <CreateUserDrawer
                            emitCloseCreateUser={closeCreateUser}
                            visible={openCreateUser}
                            emitInviteUser={onInviteUser}
                            roles={roles?.data || []}
                            user={selectedUser}
                            isSystemAdmin={useIdentity?.roles?.indexOf(SA_ROLE) !== -1}
                        />
                    ) : null}
                </Tabs.TabPane>
                <Tabs.TabPane tab={translate('users.role')} key={TabsEnum.ROLES}>
                    <RoleTabContent />
                    {openRoleDrawer ? (
                        <CreateRoleDrawer
                            emitCloseRoleDrawer={onCloseRoleDrawer}
                            emitChangedRole={onChangedRole}
                            visible={openRoleDrawer}
                            role={selectedRole}
                        />
                    ) : null}
                </Tabs.TabPane>
            </Tabs>
        </section>
    );
};

import './styles.scss';

import {
    BaseRecord,
    useCreate,
    useGetIdentity,
    useNavigation,
    useTranslate,
} from '@refinedev/core';
import { Button, Col, Form, Input, notification, Radio, Row, Space, Spin } from 'antd';
import { IssuePriority, TicketTargetEnum } from 'enums/helpdesk.enum';
import { useEffect, useState } from 'react';

import { SelectContact } from '../../../components/modules/select-contact';
import { SelectPartner } from '../../../components/modules/select-partner';
import { SelectUser } from '../../../components/modules/select-staff';
import { UserTypeEnum } from '../../../enums/user-type.enum';
import { IPartner } from '../../../interfaces/partner';
import { IUser } from '../../../interfaces/user';

enum CreateHelpdeskFormEnum {
    Subject = 'subject',
    Content = 'content',
    Priority = 'priority',
    Target = 'target',
    PartnerId = 'partnerId',
}

interface ICreateHelpdesk {
    subject: string;
    priority: IssuePriority;
    message: string;
    contactId?: string;
    partnerId?: string;
}

export const HelpdeskCreate: React.FC = () => {
    const [form] = Form.useForm<ICreateHelpdesk>();
    const translate = useTranslate();
    const { mutate } = useCreate<BaseRecord>();
    const { goBack } = useNavigation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();
    const userType = userIdentity?.userType;
    const isSa = userIdentity?.userType === UserTypeEnum.TENANT;
    const isTenantOwner = userIdentity?.isTenantOwner;
    const isClient = userIdentity?.userType === UserTypeEnum.CLIENT;
    const [partnerId, setPartnerId] = useState<string | null>(null);
    const [isContactTicket, setIsContactTicket] = useState<boolean | undefined>(undefined);
    const [isSelectUser, setIsSelectUser] = useState<boolean | undefined>(true);

    useEffect(() => {
        ((userType === UserTypeEnum.WHITELABEL && !isTenantOwner) ||
            userType === UserTypeEnum.AFFILIATE_PARTNER) &&
            setIsSelectUser(false);
    }, [userIdentity?.userType]);

    const onCreate = async () => {
        try {
            const isValid = await form.validateFields();

            if (isValid) {
                setIsLoading(true);
                const formValue = form.getFieldsValue();
                mutate(
                    {
                        resource: 'v1/tickets',
                        values: formValue,
                        successNotification: { message: 'Successfully created', type: 'success' },
                    },
                    {
                        onError: (_) => {
                            setIsLoading(false);
                        },
                        onSuccess: (_) => {
                            setIsLoading(false);
                            goBack();
                        },
                    },
                );
            } else {
                notification.error({
                    message: translate('helpdesk.require_fields'),
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onTargetChange = () => {
        form.resetFields(['contactId']);
        form.resetFields(['userId']);
    };

    const getSelectedPartner = (partner: IPartner.IPartnerInfo) => {
        form?.resetFields(['contactId', 'userId', 'target']);
        form?.setFieldsValue({ partnerId: partner.id });
        setPartnerId(partner.id || null);
        partner.userType === UserTypeEnum.AFFILIATE_PARTNER
            ? setIsSelectUser(false)
            : setIsSelectUser(true);
        form.validateFields([CreateHelpdeskFormEnum.Target]);
        setIsContactTicket(undefined);
    };

    const deselectOnClick = (e: any) => {
        const { value } = e.target as { value: TicketTargetEnum };

        switch (value) {
            case TicketTargetEnum.CONTACT: {
                const isDeselected = form.getFieldValue('target') === value;
                if (isDeselected) {
                    form.resetFields(['target']);
                    setIsContactTicket(undefined);
                } else {
                    setIsContactTicket(!isDeselected);
                }
                break;
            }
            case TicketTargetEnum.STAFF: {
                const isDeselected = form.getFieldValue('target') === value;
                setIsSelectUser(true);
                if (isDeselected) {
                    form.resetFields(['target']);
                    setIsContactTicket(undefined);
                } else {
                    setIsContactTicket(isDeselected);
                }

                break;
            }
        }
    };

    const clearPartner = () => {
        form.resetFields(['partnerId', 'contactId', 'userId', 'target']);

        setPartnerId(null);
        setIsContactTicket(undefined);
        setIsSelectUser(true);
        form.validateFields([CreateHelpdeskFormEnum.Target]);
    };

    return (
        <section className="create-helpdesk-container w-full h-full">
            <Spin spinning={isLoading}>
                <Row className="w-full h-full">
                    <Col xs={24} className="create-helpdesk-col h-full">
                        <Row>
                            <Col span={24}>
                                <section className="helpdesk-action-container w-ful">
                                    <Row className="helpdesk-action-row w-full h-full">
                                        <Col
                                            xs={12}
                                            className="helpdesk-action-col-flex items-center"
                                        >
                                            <span className="create-helpdesk-title">
                                                {translate('helpdesk.create_issue')}
                                            </span>
                                        </Col>
                                        <Col
                                            xs={12}
                                            className="helpdesk-action-col-flex items-center justify-end"
                                        >
                                            <Button onClick={goBack}>
                                                {translate('helpdesk.cancel')}
                                            </Button>
                                            <Button
                                                className="action-button create-btn ml-4"
                                                type="primary"
                                                onClick={onCreate}
                                                disabled={isLoading} // Disable button when loading
                                            >
                                                {isLoading ? (
                                                    <Spin />
                                                ) : (
                                                    translate('helpdesk.create')
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </section>
                            </Col>
                        </Row>
                        <Form
                            form={form}
                            id="createHelpdeskForm"
                            name="create-helpdesk"
                            layout="vertical"
                            className="create-helpdesk-form w-full"
                        >
                            <Row className="create-helpdesk-form-row w-full h-full">
                                <Col xs={24} sm={16} className="create-helpdesk-form-col col-left">
                                    <Form.Item
                                        className="helpdesk-form-item form-item-subject"
                                        label={translate('helpdesk.subject')}
                                        name={CreateHelpdeskFormEnum.Subject}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.subject_required'),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        className="helpdesk-form-item form-item-message"
                                        label={translate('helpdesk.message')}
                                        name={CreateHelpdeskFormEnum.Content}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.message_required'),
                                            },
                                        ]}
                                    >
                                        <Input.TextArea />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={24}
                                    sm={8}
                                    className="create-helpdesk-form-col col-right create-helpdesk-custom"
                                >
                                    <Form.Item
                                        className="helpdesk-form-item form-item-priority"
                                        label={translate('helpdesk.priority')}
                                        name={CreateHelpdeskFormEnum.Priority}
                                        rules={[
                                            {
                                                required: true,
                                                message: translate('helpdesk.priority_required'),
                                            },
                                        ]}
                                    >
                                        <Radio.Group value={IssuePriority.LOW}>
                                            <Space direction="vertical">
                                                <Radio value={IssuePriority.LOW}>
                                                    {translate('helpdesk.priority_enum.low')}
                                                </Radio>
                                                <Radio value={IssuePriority.NORMAL}>
                                                    {translate('helpdesk.priority_enum.normal')}
                                                </Radio>
                                                <Radio value={IssuePriority.HIGH}>
                                                    {translate('helpdesk.priority_enum.high')}
                                                </Radio>
                                                <Radio value={IssuePriority.CRITICAL}>
                                                    {translate('helpdesk.priority_enum.critical')}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                    {!isClient && isSa && (
                                        <Form.Item
                                            className="helpdesk-form-item"
                                            label={translate('helpdesk.partner_label')}
                                            name="partnerId"
                                        >
                                            <SelectPartner
                                                allowClear
                                                placeholder="Select Partner"
                                                getSelectedPartner={getSelectedPartner}
                                                clearPartner={clearPartner}
                                            />
                                        </Form.Item>
                                    )}
                                    {!isClient && (
                                        <div className="select-target-container">
                                            <Form.Item
                                                className="helpdesk-form-item mb-2"
                                                label={`${translate('helpdesk.to')}`}
                                                name={CreateHelpdeskFormEnum.Target}
                                                rules={[
                                                    {
                                                        required:
                                                            !form.getFieldValue(
                                                                CreateHelpdeskFormEnum.Target,
                                                            ) &&
                                                            !form.getFieldValue(
                                                                CreateHelpdeskFormEnum.PartnerId,
                                                            ),
                                                        message: translate(
                                                            'helpdesk.user_contact_partner_required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Radio.Group onChange={onTargetChange}>
                                                    <Space direction="horizontal">
                                                        <Radio
                                                            onClick={deselectOnClick}
                                                            value={TicketTargetEnum.CONTACT}
                                                        >
                                                            {translate('helpdesk.contact_label')}
                                                        </Radio>
                                                        {isSelectUser && (
                                                            <Radio
                                                                onClick={deselectOnClick}
                                                                value={TicketTargetEnum.STAFF}
                                                            >
                                                                {translate('helpdesk.user_label')}
                                                            </Radio>
                                                        )}
                                                    </Space>
                                                    A
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    )}

                                    {!isClient &&
                                        form.getFieldValue('target') &&
                                        isContactTicket && (
                                            <Form.Item
                                                name="contactId"
                                                rules={[
                                                    {
                                                        required:
                                                            form.getFieldValue(
                                                                CreateHelpdeskFormEnum.Target,
                                                            ) === TicketTargetEnum.CONTACT,
                                                        message: translate(
                                                            'helpdesk.contact_required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <SelectContact
                                                    getPopupContainer={(trigger) =>
                                                        trigger.parentNode
                                                    }
                                                    allowClear
                                                    placeholder={translate(
                                                        'helpdesk.select_contact',
                                                    )}
                                                    disabled={false}
                                                    partnerId={partnerId}
                                                    isOnlyContact={!partnerId && isContactTicket}
                                                ></SelectContact>
                                            </Form.Item>
                                        )}

                                    {!isClient &&
                                        form.getFieldValue('target') &&
                                        !isContactTicket &&
                                        isSelectUser && (
                                            <Form.Item
                                                name="userId"
                                                rules={[
                                                    {
                                                        required:
                                                            form.getFieldValue(
                                                                CreateHelpdeskFormEnum.Target,
                                                            ) === TicketTargetEnum.STAFF,
                                                        message:
                                                            translate('helpdesk.user_required'),
                                                    },
                                                ]}
                                            >
                                                <SelectUser
                                                    getPopupContainer={(trigger) =>
                                                        trigger.parentNode
                                                    }
                                                    allowClear
                                                    placeholder={translate('helpdesk.select_user')}
                                                    disabled={false}
                                                    partnerId={partnerId}
                                                ></SelectUser>
                                            </Form.Item>
                                        )}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        </section>
    );
};

import './styles.scss';

import { CustomSearch } from '@components/modules/custom-search';
import { IInvoice } from '@interfaces/invoice';
import { NPDFMakeSetting } from '@interfaces/pdf-make.setting';
import { useApiUrl, useCustom, useList } from '@refinedev/core';
import { Tabs } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { Content } from 'pdfmake/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DownloadPrint } from '../download-pdf';
import { ContactInvoiceTabContent } from './contact-invoice-list';
import { renderInvoiceSummary, renderInvoiceTable } from './contact-invoice-list/print-pdf-invoice';

enum TabsEnum {
    INVOICES = 'contactInvoices',
}
export const PAGE_SIZE = 5;
export const ContactCrmInvoicesPayments = ({
    contactId,
    contactName,
}: {
    contactId: string;
    contactName: string;
}) => {
    const { t } = useTranslation(['common', 'contact', 'quote']);

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const [tab, setTab] = useState<TabsEnum>(TabsEnum?.INVOICES);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [filter, setFilter] = useState<string>('');
    const [dataToPrint, setDataToPrint] = useState<NPDFMakeSetting.IReportBody>();
    const [invoiceData, setInvoiceData] = useState<IInvoice.Invoice[]>();

    const onChangeTab = (e: TabsEnum) => {
        setTab(e);
    };

    const {
        data: tableQueryInvoiceResult,
        refetch: invoiceRefetch,
        isLoading: invoiceLoading,
    } = useList<IInvoice.Invoice>({
        dataProviderName,
        resource: 'v1/contacts/get-invoices',
        queryOptions: {
            enabled: false,
        },
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'contactId',
                    operator: 'eq',
                    value: contactId,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: pageIndex,
                },
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: PAGE_SIZE,
                },
                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },
            ],
            sort: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
    });

    useEffect(() => {
        if (contactId) {
            switch (tab) {
                case TabsEnum.INVOICES: {
                    invoiceRefetch();
                    break;
                }
            }
        }
    }, [pageIndex, contactId, filter]);

    useEffect(() => {
        if (contactId) {
            setPageIndex(1);
            switch (tab) {
                case TabsEnum.INVOICES: {
                    invoiceRefetch();
                    break;
                }
            }
        }
    }, [tab]);

    useEffect(() => {
        let summaryData: Content[] = [];
        const tableData: Content[] = [];

        if (invoiceData) {
            summaryData = renderInvoiceSummary(invoiceData, (rs: string, obj: any) => t(rs, obj));
        }
        if (invoiceData?.length) {
            const title = '';
            const resultMapTable = renderInvoiceTable(invoiceData, (rs: string, obj: any) =>
                t(rs, obj),
            );
            tableData.push(title, resultMapTable);
        }

        const InvoiceData: NPDFMakeSetting.IReportBody = {
            summarySection: summaryData || [],
            tableBody: tableData || [],
        };

        setDataToPrint(InvoiceData);
    }, [invoiceData]);

    const searchElement = useMemo(() => {
        return (
            <div className="flex">
                <CustomSearch
                    placeholder={
                        tab === TabsEnum?.INVOICES
                            ? t('quotes.fields.search.invoce_placeholder', { ns: 'quote' })
                            : t('quotes.fields.search.recurring_invoice_placeholder', {
                                  ns: 'quote',
                              })
                    }
                    className={'search-item p-3'}
                    onChange={(event: any) => {
                        setFilter(event?.target.value);
                        setPageIndex(1);
                    }}
                    allowClear={true}
                />
                {tab === TabsEnum.INVOICES && (
                    <DownloadPrint
                        title={`${t('contacts.pdf.invoice.invoice_of', {
                            ns: 'contact',
                        })} ${contactName}`}
                        contactName={contactName}
                        dataToPrint={dataToPrint}
                    />
                )}
            </div>
        );
    }, [tab, dataToPrint]);

    return (
        <div className="contact-payments-invoices overflow-hidden">
            <Tabs
                tabBarExtraContent={searchElement}
                onChange={(e) => onChangeTab(e as TabsEnum)}
                defaultActiveKey={TabsEnum.INVOICES}
            >
                <Tabs.TabPane tab={t('contact.invoices', { ns: 'common' })} key={TabsEnum.INVOICES}>
                    <ContactInvoiceTabContent
                        cols={tableQueryInvoiceResult?.data || []}
                        current={pageIndex}
                        setCurrent={setPageIndex}
                        tableResult={tableQueryInvoiceResult}
                        pageSize={PAGE_SIZE}
                        setDataToPrint={(value: IInvoice.Invoice[]) => setInvoiceData(value)}
                        invoiceLoading={invoiceLoading}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

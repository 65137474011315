import { PartnerTypeEnum } from '@enums/partner-type.enum';
import { Create, Edit, SaveButton } from '@refinedev/antd';
import { useTranslate } from '@refinedev/core';
import { ButtonProps, Drawer, DrawerProps, FormProps, Grid } from 'antd';
import { useState } from 'react';

import { PartnerDetailCrmForm } from './detail-form';

type PartnerDetailsProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    isEditMode: boolean;
    type: PartnerTypeEnum;
};

export const PartnerCrmDetail: React.FC<PartnerDetailsProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    isEditMode,
    type,
}) => {
    const t = useTranslate();
    const [loading, setLoading] = useState<boolean>(false);
    const breakpoint = Grid.useBreakpoint();
    const initialValues: any =
        !isEditMode && !formProps.initialValues?.id
            ? { isActive: true }
            : {
                  ...formProps.initialValues,
              };

    return drawerProps.open ? (
        <Drawer
            {...drawerProps}
            width={breakpoint.md ? '700px' : '100%'}
            maskClosable={false}
            zIndex={999}
            className="drawer-form-container contact-drawer partner-drawer"
            afterOpenChange={() => {
                formProps.form?.resetFields();
            }}
            title={
                <span className="text-lg">
                    {!isEditMode
                        ? `${t('permissions.pos_partner_create')}`
                        : `${t('permissions.pos_partner_edit')}`}
                </span>
            }
            extra={<SaveButton {...saveButtonProps} />}
        >
            {!isEditMode ? (
                <Create
                    breadcrumb={null}
                    isLoading={loading}
                    footerButtons={<></>}
                    headerProps={{ extra: null, title: '', backIcon: false }}
                >
                    <PartnerDetailCrmForm type={type} formProps={formProps} mode={'add'} />
                </Create>
            ) : (
                <Edit
                    breadcrumb={null}
                    isLoading={loading || !initialValues?.id ? true : false}
                    footerButtons={<></>}
                    headerProps={{ extra: null, title: '', backIcon: false }}
                >
                    <PartnerDetailCrmForm type={type} formProps={formProps} mode={'edit'} />
                </Edit>
            )}
        </Drawer>
    ) : (
        <></>
    );
};

import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { ContactNoteItemForm } from '@components/contact-note/note-form';
import { ContactNoteList } from '@components/contact-note/note-list';
import { UserStatusEnum } from '@enums/user-status.enum';
import { IContact } from '@interfaces/contact';
import { useModal } from '@refinedev/antd';
import { useCustom } from '@refinedev/core';
import { formatPrice } from '@utils/resource';
import { Button, Modal } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { getEnvConfig } from 'getEnvConfig';
import { useTranslation } from 'react-i18next';

import { ContactCrmNoteList } from '../contact-note/note-list';

export const ContactCrmInsights: React.FC<{
    contactId: string;
    contactStatus?: UserStatusEnum;
    userId?: string;
}> = ({ contactId }) => {
    const { t } = useTranslation(['quote', 'common', 'contact']);

    const apiUrl = getEnvConfig.PORTAL_URL;
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const { data: insightsData } = useCustom<IContact.IInvoicesOfContactDto>({
        dataProviderName,
        url: `${apiUrl}/v1/contacts/invoices-contact/${contactId}`,
        method: 'get',
    });

    // Show modal and Add new note for contact
    const { modalProps: modalNoteProps, show: showModalNote, close: closeModalNote } = useModal();
    const handleAddNewNote = () => {
        showModalNote();
    };
    return (
        <>
            <div className="flex flex-col">
                <div className="mb-4">
                    <div className="mb-2 font-bold title">
                        {t('contact.insight', { ns: 'common' })}
                    </div>
                    <div className="contact-insight contact-total-insight">
                        <h4>{t('contact.total_spend', { ns: 'common' })}</h4>
                        <h3 className="mb-2">{formatPrice(insightsData?.data.sumTransaction)}</h3>
                        <span className="text-gray-400">
                            {insightsData?.data.totalTransaction}{' '}
                            {insightsData?.data.totalTransaction &&
                            insightsData.data.totalTransaction > 1
                                ? t('contact.transactions', { ns: 'common' })
                                : t('contact.transaction', { ns: 'common' })}
                        </span>
                    </div>
                </div>

                <div className="subscription">
                    <div className="flex flex-row justify-between items-center">
                        <div className="my-4 font-bold title">
                            {t('contacts.note', { ns: 'contact' })}
                        </div>
                        <Button
                            onClick={() => handleAddNewNote()}
                            className="contact-button"
                            type="text"
                            icon={<PlusOutlined />}
                        >
                            {t('add_new', { ns: 'common' })}
                        </Button>
                    </div>
                    {contactId && <ContactCrmNoteList contactId={contactId} />}
                </div>

                <Modal
                    {...modalNoteProps}
                    title={t('contacts.note_content.add_new', { ns: 'contact' })}
                    maskClosable={false}
                    footer={null}
                    width={650}
                    wrapClassName="payment-modal"
                >
                    <div className="mt-2">
                        <ContactNoteItemForm
                            contactId={contactId}
                            onCancelEdit={() => {
                                closeModalNote();
                            }}
                            onUpdate={() => {
                                closeModalNote();
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
};

export const MQTT_TOPIC = {
    CONVERSATION: 'conversation_',
    CONVERSATION_MESSAGE: 'conversation_message_',
    TICKET: 'ticket_',
    TICKET_MESSAGE: 'ticket_message_',
    CLOSE_TICKET: 'close_ticket_',
    NOTIFICATION: 'notification_',
    UPLOAD_AVATAR: 'upload_avatar_',
    SEND_NOTIFICATION: 'send_notification_',
};

import { NPDFMakeSetting } from '@interfaces/pdf-make.setting';
import { assignLayoutTable, defaultStyle, footer, header, headerBody, styles } from '@utils/pdf';
import { Button } from 'antd';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Content, Node, TDocumentDefinitions } from 'pdfmake/interfaces';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DownloadPrintProps = {
    title: string;
    contactName: string;
    dataToPrint?: NPDFMakeSetting.IReportBody;
};

export const DownloadPrint: FC<DownloadPrintProps> = ({ title, contactName, dataToPrint }) => {
    const [content, setContent] = useState<Content[]>([]);
    const objDefinition: TDocumentDefinitions = {
        pageSize: 'A4',
        pageMargins: [40, 80, 40, 80],
        content: content,
        styles: styles(),
        header: header(title, ''),
        defaultStyle: defaultStyle(),
        footer: (currentPage: number) => footer(currentPage),
        pageBreakBefore: function (currentNode: Node, followingNodesOnPage: Array<Node>) {
            return (
                currentNode.headlineLevel === 1 &&
                !followingNodesOnPage.filter((node: Node) => node.headlineLevel === 3).length
            );
        },
    };
    const { t } = useTranslation('common');
    useEffect(() => {
        const newContent: Content[] = [];

        const columnData: any = headerBody((rs: string, obj: any) => t(rs, obj), contactName);
        if (dataToPrint?.summarySection.length) {
            dataToPrint.summarySection.forEach((item: any) => {
                columnData.push(item);
            });
        }
        newContent.push(columnData);
        if (dataToPrint?.tableBody.length) {
            dataToPrint?.tableBody.forEach((item: any) => {
                assignLayoutTable(item);
                newContent.push(item);
            });
        }

        if (dataToPrint?.tableBodyQty?.length) {
            dataToPrint?.tableBodyQty.forEach((item: any) => {
                assignLayoutTable(item);
                newContent.push(item);
            });
        }
        setContent(newContent);
    }, [contactName, dataToPrint]);

    const handleDownload = () => {
        const pdfDocGenerator = pdfMake.createPdf(objDefinition);
        pdfDocGenerator.download(`${title}.pdf`);
    };

    return (
        <div className={`flex justify-between items-center md:ml-4 export-report`}>
            <Button
                type="link"
                icon={<img src="/images/icons/download.svg" />}
                className="mr-2"
                onClick={handleDownload}
            />
        </div>
    );
};

import { ITenant } from '@interfaces/tenant';
import BaseApi from './baseApi';

export class TenantService extends BaseApi {
    constructor() {
        super();
    }

    private readonly BASE_URL = 'v1/tenant';

    public async getTenants(): Promise<ITenant.ITenantInfor[] | []> {
        const result = await this.get<ITenant.ITenantInfor[] | []>(
            `${this.BASE_URL}/active-tenants`,
        );
        return result?.data || [];
    }
}

import './styles.scss';

import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { CustomSearch } from '@components/modules/custom-search';
import { CustomPagination } from '@components/modules/pagination';
import { PAGE_SIZE } from '@constants/index.constant';
import { MQTT_TOPIC } from '@constants/mqtt-topic';
import { PERMISSIONS } from '@constants/permission';
import { NotificationPortalStatusEnum } from '@enums/notification-portal.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { INotificationPortal } from '@interfaces/notification-portal';
import { IUser } from '@interfaces/user';
import { useSubscription } from '@nuvioo/core-ui';
import { CreateButton, getDefaultSortOrder, mapAntdSorterToCrudSorting } from '@refinedev/antd';
import {
    BaseKey,
    BaseRecord,
    useDelete,
    useGetIdentity,
    useNavigation,
    usePermissions,
    useTable,
    useTranslate,
} from '@refinedev/core';
import { formatDate } from '@utils/resource';
import {
    Button,
    Col,
    Dropdown,
    Image,
    Menu,
    Popconfirm,
    Row,
    Table,
    TablePaginationConfig,
} from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { ChangeEvent, useEffect, useState } from 'react';

export const NotificationPortalIndex = () => {
    const translate = useTranslate();

    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const { data: permissions } = usePermissions<string[]>();

    const { mutate } = useDelete<BaseRecord>();
    const { push } = useNavigation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<INotificationPortal.INotification[]>([]);

    const [filter, setFilter] = useState<string>('');
    const { message } = useSubscription.default(
        `${MQTT_TOPIC.SEND_NOTIFICATION}${userIdentity?.id}`,
    );
    const {
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
        setFilters,
        filters,
        setSorters,
        sorters,
    } = useTable<INotificationPortal.INotification>({
        resource: 'v1/tenant/notification-portal',
        pagination: {
            mode: 'server',
            pageSize: PAGE_SIZE,
        },

        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc',
                },
            ],
        },

        filters: {
            initial: [
                {
                    field: 'parentId',
                    operator: 'eq',
                    value: userIdentity && userIdentity.id,
                },
                {
                    field: 'filter',
                    value: filter,
                    operator: 'eq',
                },
            ],
        },
    });

    const showItem = (id: string): void => {
        push(`/tenant/notification-portal/show/${id}`);
    };

    useEffect(() => {
        if (message) {
            setIsLoading(true);
            if (message.message) {
                const newNotification = JSON.parse(message.message.toString());
                setNotifications((prevNotifications) => {
                    return prevNotifications.map((item) => {
                        if (item.id === newNotification.notificationPortalId) {
                            return { ...item, status: NotificationPortalStatusEnum.SUCCEEDED };
                        }
                        return item;
                    });
                });

                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }
    }, [message, notifications]);

    useEffect(() => {
        if (tableQueryResult?.data?.data) {
            setNotifications(tableQueryResult.data?.data as INotificationPortal.INotification[]);
        }
    }, [tableQueryResult?.data?.data]);

    const onChangeTable = (
        pagination: TablePaginationConfig,
        tableFilters: Record<
            string,
            (string | number | boolean) | (string | number | boolean)[] | null
        >,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const deleteNotification = async (id: string) => {
        if (!id) {
            return;
        }
        mutate(
            {
                resource: 'v1/tenant/notification-portal',
                id: id as BaseKey,
                successNotification: {
                    message: translate('delete_success', { ns: 'common' }),
                    type: 'success',
                },
                errorNotification: {
                    message: translate('delete_error', { ns: 'common' }),
                    type: 'error',
                },
            },
            {
                onSuccess: (_) => {
                    const _isLastItem = tableQueryResult?.data?.data.length === 1;
                    _isLastItem && current > 1
                        ? setCurrent((prev) => prev - 1)
                        : tableQueryResult.refetch();
                },
            },
        );
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const filter = event.target.value;

        if (filter) {
            setFilter(filter);
        } else {
            setFilters([
                {
                    field: 'filter',
                    value: '',
                    operator: 'eq',
                },
            ]);
        }
    };

    useEffect(() => {
        tableQueryResult.refetch();
    }, [current, filters, sorters]);

    useEffect(() => {
        if (filter) {
            setFilters([
                {
                    field: 'filter',
                    value: filter,
                    operator: 'eq',
                },
            ]);
        }
    }, [filter]);

    const menu = (id: string, record: INotificationPortal.INotification) => (
        <Menu mode="vertical">
            <Menu.Item
                key="1"
                icon={
                    <Button
                        onClick={() => showItem(record.id)}
                        className="contact-button"
                        disabled={isLoading}
                        type="text"
                        icon={
                            <EyeOutlined
                                style={{
                                    color: 'green',
                                }}
                            />
                        }
                    >
                        <span>{translate('actions.show', { ns: 'common' })}</span>
                    </Button>
                }
            ></Menu.Item>

            <Menu.Item
                key="2"
                icon={
                    <Button
                        onClick={() => deleteNotification(id)}
                        className="p-0 border-0 delete-button"
                        danger
                        icon={<DeleteOutlined />}
                        disabled={isLoading}
                    >
                        {translate('actions.delete', { ns: 'common' })}
                    </Button>
                }
            ></Menu.Item>
        </Menu>
    );

    if (userIdentity?.userType !== UserTypeEnum.SYS_ADMIN) {
        push('/404');

        return <></>;
    }

    return (
        <>
            <section className="notification-portal-container w-full h-full">
                <Row className="w-full h-10 mb-6 header-action-row">
                    <Col span={4}>
                        <CustomSearch
                            placeholder={translate('notification_portal.search_notification')}
                            className={'search-item'}
                            onChange={(event) => onSearch(event)}
                            allowClear={true}
                        />
                    </Col>
                    {(permissions || []).includes(PERMISSIONS.NOTIFICATION_PORTAL_CREATE) ? (
                        <Col span={20} className="header-action-col justify-end">
                            <CreateButton
                                icon={false}
                                className="create-issue-button"
                                type="primary"
                            >
                                <Image preview={false} src={'/images/icons/plus.svg'} />
                                <span className="ml-2">
                                    {translate('notification_portal.create_notification')}
                                </span>
                            </CreateButton>
                        </Col>
                    ) : null}
                </Row>
                <Row className="notification-portal-table-container">
                    <Col span={24} className="flex flex-col">
                        <Table
                            dataSource={notifications}
                            loading={tableQueryResult.isLoading}
                            pagination={false}
                            rowKey="id"
                            className="notification-portal-table"
                            onChange={onChangeTable}
                            scroll={{ y: 'auto', x: 1024 }}
                        >
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
                                title={translate('helpdesk.created_at')}
                                key="createdAt"
                                dataIndex="createdAt"
                                width={150}
                                render={(text) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                            <Table.Column
                                title={translate('helpdesk.subject')}
                                key="subject"
                                dataIndex="subject"
                                render={(text) => (
                                    <div className="issue-cell">
                                        <div className="issue-cell-subject">{text}</div>
                                    </div>
                                )}
                            />
                            <Table.Column
                                title={translate('helpdesk.message')}
                                key="content"
                                dataIndex="content"
                                render={(text) => (
                                    <div className="issue-cell">
                                        <div className="issue-cell-subject">
                                            {text?.length > 50
                                                ? text.substring(0, 50) + '...'
                                                : text}
                                        </div>
                                    </div>
                                )}
                            />

                            <Table.Column
                                title={translate('notification_portal.status')}
                                key="status"
                                dataIndex="status"
                                render={(text) => (
                                    <div className="issue-cell">
                                        <div className="issue-cell-subject">{text}</div>
                                    </div>
                                )}
                            />

                            <Table.Column
                                dataIndex="id"
                                key="action"
                                width={50}
                                fixed="right"
                                render={(id, record: any, _) => (
                                    <div
                                        className="flex justify-between items-center"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <Dropdown
                                            overlay={menu(id, record)}
                                            placement="bottomRight"
                                            className="w-8 ml-auto"
                                            arrow
                                            overlayClassName="invoice-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <div>
                                                <img
                                                    src="/images/icons/dots-vertical.svg"
                                                    alt="more"
                                                    className="cursor-pointer"
                                                />
                                            </div>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </Table>
                        <div className="pagination-container pt-3 pb-4 px-6">
                            <CustomPagination
                                pageSize={pageSize}
                                total={tableQueryResult?.data?.total}
                                current={current}
                                onChange={(value: number, size: number) => {
                                    setCurrent(value);
                                    setPageSize(size);
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    );
};

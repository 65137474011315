import './styles.scss';

import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { MQTT_TOPIC } from '@constants/mqtt-topic';
import { NotificationPortalStatusEnum } from '@enums/notification-portal.enum';
import { INotificationPortal } from '@interfaces/notification-portal';
import { useSubscription } from '@nuvioo/core-ui';
import { FilterDropdownProps } from '@refinedev/antd/dist/components/table/components/filterDropdown';
import { BaseKey, useCreate, useNavigation, useOne } from '@refinedev/core';
import { formatDate } from '@utils/resource';
import type { InputRef } from 'antd';
import {
    Button,
    Card,
    Col,
    Image,
    Input,
    notification,
    Row,
    Space,
    Spin,
    Table,
    Tooltip,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TENANT_RESOURCE_API } from 'pages/settings/user-settings';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export interface SendEmailDto {
    tenantId: string;
    email: string;
    status: NotificationPortalStatusEnum;
}

const NotificationPortalDetail = () => {
    const { t } = useTranslation(['common']);

    const { id } = useParams();
    const { goBack } = useNavigation();
    const { mutate } = useCreate();

    const [tenants, setTenants] = useState<React.Key[]>([]);
    const [tenantNotification, setTenantNotification] = useState<
        INotificationPortal.ITenantNotification[]
    >([]);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState('');
    const searchInput = useRef<InputRef>(null);
    const { message } = useSubscription.default(`${MQTT_TOPIC.SEND_NOTIFICATION}${id}`);

    const {
        data: notificationDetail,
        isLoading,
        refetch,
    } = useOne<INotificationPortal.INotification>({
        resource: 'v1/tenant/notification-portal',
        id: id as BaseKey,
        queryOptions: {
            enabled: false,
        },
    });

    useEffect(() => {
        if (id) {
            refetch();
        }
    }, [id]);

    useEffect(() => {
        if (notificationDetail?.data) {
            setTenantNotification(notificationDetail?.data?.tenants);
        }
    }, [notificationDetail?.data]);

    useEffect(() => {
        if (message) {
            setLoading(true);
            if (message.message) {
                const newNotification = JSON.parse(message.message.toString());

                const updateTenantNotifications = tenantNotification.map((tenant) => {
                    const newTenant = (newNotification?.tenants || []).find(
                        (newTenant: SendEmailDto) => newTenant.tenantId === tenant.tenantId,
                    );
                    if (newTenant) {
                        return {
                            ...tenant,
                            tenantStatus: newTenant.status,
                        };
                    }
                }) as INotificationPortal.ITenantNotification[];

                setLoading(false);
                if (updateTenantNotifications.length) {
                    setTenantNotification(updateTenantNotifications);
                }
            } else {
                setLoading(false);
            }
        }
    }, [message, tenantNotification]);

    const sendEmail = (userId: string) => {
        if (!userId) return;
        setLoading(true);
        mutate(
            {
                resource: `${TENANT_RESOURCE_API}/notification-portal/resend/${userId}`,
                meta: { method: 'POST' },
                values: { id },
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: async (data) => {
                    if (data) {
                        await refetch();
                        notification.success({
                            message: t('notification_portal.resend_success'),
                            type: 'success',
                        });
                        setLoading(false);
                    }
                },
            },
        );
    };

    const resendNotification = () => {
        if (!tenants.length) return;
        const values = {
            tenantIds: tenants,
            id,
        };
        setLoading(true);
        mutate(
            {
                resource: `${TENANT_RESOURCE_API}/notification-portal/resend`,
                meta: { method: 'POST' },
                values,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: async (data) => {
                    if (data) {
                        await refetch();
                        notification.success({
                            message: t('notification_portal.resend_success'),
                            type: 'success',
                        });
                        setLoading(false);
                    }
                },
            },
        );
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setTenants(selectedRowKeys);
        },
        getCheckboxProps: (record: INotificationPortal.ITenantNotification) => ({
            disabled: record.tenantStatus === NotificationPortalStatusEnum.SUCCEEDED, // Column configuration not to be checked
            name: record.tenantStatus,
        }),
    };

    const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm']) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const columns: ColumnsType<INotificationPortal.ITenantNotification> = [
        {
            title: (
                <div className="table-thead-text rounded justify-center">
                    {t('notification_portal.number', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'number',
            key: 'number',
            render: (text, _, index) => (
                <div className="">
                    <p className="table-tbody-text">{index + 1}</p>
                </div>
            ),
            className: 'w-2',
        },

        {
            title: (
                <div className="table-thead-text rounded name-column">
                    {t('notification_portal.name', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'tenantName',
            key: 'tenantName',
            render: (tenantName) => (
                <div className="flex justify-start">
                    <p className="table-tbody-text">{tenantName}</p>
                </div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            {t('notification_portal.search')}
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            {t('notification_portal.reset')}
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record['tenantName']
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()),
            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        },
        {
            title: (
                <div className="table-thead-text rounded name-column">
                    {t('notification_portal.email', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'tenantEmail',
            key: 'tenantEmail',
            render: (tenantEmail) => (
                <div className="flex justify-start">
                    <p className="table-tbody-text">{tenantEmail}</p>
                </div>
            ),
        },

        {
            title: (
                <div className="table-thead-text flex rounded justify-center">
                    {t('notification_portal.status', { ns: 'common' })}
                </div>
            ),
            dataIndex: 'tenantStatus',
            key: 'tenantStatus',
            width: 200,
            render: (tenantStatus) => (
                <p>
                    {tenantStatus === NotificationPortalStatusEnum.SUCCEEDED && (
                        <p className="table-tbody-text  flex justify-center">
                            <img src={'/images/enable.svg'} alt="enable" width={14} />
                        </p>
                    )}
                </p>
            ),
            filters: [
                {
                    text:
                        NotificationPortalStatusEnum.FAILURE.charAt(0).toUpperCase() +
                        NotificationPortalStatusEnum.FAILURE.slice(1),
                    value: NotificationPortalStatusEnum.FAILURE,
                },
                {
                    text:
                        NotificationPortalStatusEnum.SUCCEEDED.charAt(0).toUpperCase() +
                        NotificationPortalStatusEnum.SUCCEEDED.slice(1),
                    value: NotificationPortalStatusEnum.SUCCEEDED,
                },
            ],
            onFilter: (value, record) => {
                const status =
                    record.tenantStatus === NotificationPortalStatusEnum.PENDING
                        ? NotificationPortalStatusEnum.FAILURE
                        : record.tenantStatus;

                return status.startsWith(value as string);
            },
        },

        {
            title: (
                <>
                    {notificationDetail?.data?.tenants?.some(
                        (tenant) => tenant.tenantStatus !== NotificationPortalStatusEnum.SUCCEEDED,
                    ) && (
                        <div className="table-thead-text flex rounded justify-center">
                            {t('notification_portal.action', { ns: 'common' })}
                            <Tooltip title={<span>{t('notification_portal.resend_explain')}</span>}>
                                <QuestionCircleOutlined
                                    style={{ color: 'grey' }}
                                    className="ml-1"
                                />
                            </Tooltip>
                        </div>
                    )}
                </>
            ),
            dataIndex: 'Action',
            key: 'Action',
            render: (__, current) => (
                <div className="flex justify-around">
                    {current.tenantStatus !== NotificationPortalStatusEnum.SUCCEEDED ? (
                        <Button
                            className="mr-5 p-0 w-4 border-0"
                            onClick={() => sendEmail(current?.tenantId ?? '')}
                            title={t('users.invite_tooltip')}
                        >
                            <Image
                                preview={false}
                                src="/images/icons/send.svg"
                                alt="more"
                                className="cursor-pointer"
                                title={t('notification_portal.resend')}
                            />
                        </Button>
                    ) : null}
                </div>
            ),
            className: 'w-32',
        },
    ];
    const hasSelected = tenants.length > 0;
    return (
        <section className="application-detail-container">
            <div className="detail-application-wrapper">
                <Spin spinning={isLoading}>
                    <div className="back-to-list">
                        <img
                            src="/images/icons/back-to-list.svg"
                            alt="back-to-list"
                            className="back-to-list"
                            onClick={goBack}
                        />
                        <p onClick={goBack} className="text-back">
                            {t('marketplace.back')}
                        </p>
                    </div>
                </Spin>
            </div>

            <div className="flex justify-end items-center">
                <span style={{ marginRight: 8 }}>
                    {hasSelected
                        ? `${t('notification_portal.select_tenants', {
                              number: tenants.length,
                          })}${tenants.length > 1 ? 's' : ''}`
                        : ''}
                </span>
                <Button
                    type="primary"
                    disabled={!hasSelected}
                    loading={loading}
                    onClick={() => resendNotification()}
                >
                    {t('notification_portal.resend')}
                </Button>
            </div>

            <Row gutter={[16, 24]}>
                <Col xs={24} md={12} lg={8}>
                    <section className="py-4 table-item-sold">
                        <h3 className="mb-2">{t('notification_portal.content')}</h3>
                        <Card
                            title={`${t('notification_portal.subject')}: ${
                                notificationDetail?.data?.subject
                            }`}
                            bordered={false}
                            loading={isLoading}
                        >
                            <p> {notificationDetail?.data?.content}</p>

                            <div className="border-send-email"></div>
                            <div className="date-send-email">
                                <span className="created-at">
                                    {t('notification_portal.created_at')}:{' '}
                                </span>
                                <span className="date">
                                    {formatDate(notificationDetail?.data?.createdAt)}
                                </span>
                            </div>
                        </Card>
                    </section>
                </Col>
                <Col xs={24} md={12} lg={16}>
                    <section className="py-4 table-item-sold">
                        <h3 className="mb-2">{t('notification_portal.receiver')}</h3>
                        <div style={{ height: '420px', overflowX: 'auto' }}>
                            <Table
                                rowSelection={rowSelection}
                                dataSource={[...tenantNotification]}
                                loading={isLoading}
                                rowKey="tenantId"
                                columns={columns}
                                pagination={false}
                                scroll={{ x: 450 }}
                                getPopupContainer={() => {
                                    return document.querySelector?.(
                                        '.ant-table-thead',
                                    ) as HTMLElement;
                                }}
                            />
                        </div>
                    </section>
                </Col>
            </Row>
        </section>
    );
};

export default NotificationPortalDetail;
